import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmailValidator from 'email-validator';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { z } from 'zod';

import LogomarkIcon from '@/@assets/logomark.svg';
import GoogleLogo from '@/assets/google-logo.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { InputContainer } from '@/modules/form/container';
import { createForm, isFormValid, useFormValue, useIsFormValid } from '@/modules/form/form';
import { getSigninPath, RementoPage } from '@/modules/routing';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { isInApp } from '@/modules/routing/utils/app';
import { InvalidEmailLinkModal } from '@/modules/signin/components/InvalidEmailLinkModal/InvalidEmailLinkModal';
import { ReSignInModal } from '@/modules/signin/components/ReSignInModal/ReSignInModal';
import { useServices } from '@/Services';
import { useSignOut } from '@/services/api/auth/auth.service.hook';
import { AuthProvider, SignInResponse } from '@/services/api/auth/auth.types';
import { captureException } from '@/utils/captureException';
import { genitiveCase } from '@/utils/genitiveCase';
import { useStateRef } from '@/utils/useStateRef';

import {
  Container,
  Divider,
  HiddenView,
  LoginLink,
  Logomark,
  SignInContent,
  SignInLink,
  Wordmark,
} from './SigninPage.styles';

const signinFormSchema = z.object({
  email: z.string().refine(EmailValidator.validate, { message: 'Please enter a valid email' }),
});

type SigninStep = 'choose-providers' | 'email-sent';

interface SignInProps {
  isInvite?: boolean;
}

function Signin({ isInvite = false }: SignInProps) {
  const { authService, userService, redirectService, aclService } = useServices();
  const signOut = useSignOut();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Search params
  const inviteToken = searchParams.get('inviteToken');
  const inviteTokenPayload = useMemo(
    () => (inviteToken ? aclService.decodeInviteToken(inviteToken) : null),
    [aclService, inviteToken],
  );

  // Keep supporting the fallback-signin-email to not break old emails
  const defaultEmail = searchParams.get('default-signin-email') ?? searchParams.get('fallback-signin-email');
  const backupLocalData = searchParams.get('backup-local-data') === 'true';
  const [step, setStep] = useState<SigninStep>('choose-providers');

  const form = useMemo(
    () =>
      createForm({
        validation: { mode: 'onChange', display: 'onTouched' },
        schema: signinFormSchema,
        defaultValues: { email: defaultEmail ?? undefined },
      }),
    [defaultEmail],
  );
  const formValues = useFormValue(form);
  const isValidForm = useIsFormValid(form);

  // Errors modals
  const [reSigninModalOpen, setReSigninModalOpen] = useState(false);
  const [invalidEmailLinkModalOpen, setInvalidEmailLinkModalOpen] = useState(false);

  // Signin
  const [isSubmitting, setIsSubmitting, isSubmittingRef] = useStateRef(false);

  const isGmail = (formValues?.email ?? '').endsWith('@gmail.com');

  const handleSendSigninEmailLink = async () => {
    const email = formValues?.email;
    if (!isFormValid(form) || !email) {
      return;
    }

    setIsSubmitting(true);
    const submittingToastId = toast('Sending sign in link...', 'root-toast', 'default');

    try {
      await authService.sendEmailSignInLink(email, backupLocalData);
      toast('Email sign in link sent!', 'root-toast', 'default');
      setStep('email-sent');
    } catch (error) {
      captureException(error);
      toast('Failed to send email sign in link', 'root-toast', 'error');
    } finally {
      removeToast(submittingToastId);
      setIsSubmitting(false);
    }
  };

  const handleSignIn = useCallback(
    async (provider: AuthProvider) => {
      // Do not sign in if user is already signed in
      const currentUser = await userService.getUser();
      if (currentUser != null) {
        return;
      }

      if (isSubmittingRef.current) {
        return;
      }

      setIsSubmitting(true);

      const toastText = 'Logging in...';
      const loggingInToastId = toast(toastText, 'root-toast', 'default', {
        autoClose: false,
        draggable: false,
      });

      try {
        let response: SignInResponse;

        switch (provider) {
          case 'email':
            const emailLink = window.location.href;
            const url = new URL(window.location.href);
            const urlEmail = url.searchParams.get('email');
            if (!urlEmail) {
              throw new Error('There is no email in the signin link');
            }

            response = await authService.signInWithEmail(urlEmail, emailLink, 'host');

            // Remove the default email param
            if (searchParams.has('default-signin-email') || searchParams.has('fallback-signin-email')) {
              const params = new URLSearchParams(searchParams);
              params.delete('default-signin-email');
              params.delete('fallback-signin-email');
              setSearchParams(params);
            }
            break;
          case 'google':
            response = await authService.signInWithGoogle('host');
            break;
          case 'apple':
            response = await authService.signInWithApple('host');
            break;
        }

        const redirect = await redirectService.consumeRedirect('signed-in');
        if (redirect !== null) {
          const url = new URL(redirect, window.location.origin);
          if (response.isNewUser) {
            url.searchParams.set('new-account', 'true');
          }
          navigate(url.pathname + url.search);
        } else {
          navigate(RoutePath.Root);
        }
      } catch (error) {
        if (error instanceof FirebaseError) {
          logger.warn('SIGNIN_FAILED', error);

          if (error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
            toast('Login cancelled', 'root-toast', 'error');
            return;
          }
          if (error.code === AuthErrorCodes.EXPIRED_POPUP_REQUEST || error.code === AuthErrorCodes.EXPIRED_OOB_CODE) {
            toast('Login expired, please try again', 'root-toast', 'error');
            return;
          }
          if (error.code === AuthErrorCodes.INVALID_OOB_CODE) {
            setInvalidEmailLinkModalOpen(true);
            return;
          }
        }

        captureException(error);
        toast('Error signing in', 'root-toast', 'error');
      } finally {
        removeToast(loggingInToastId);
        setIsSubmitting(false);
      }
    },
    [
      userService,
      isSubmittingRef,
      setIsSubmitting,
      redirectService,
      authService,
      searchParams,
      setSearchParams,
      navigate,
    ],
  );

  const handleReSignIn = useCallback(async () => {
    setReSigninModalOpen(false);
    await signOut(false);
    handleSignIn('email');
  }, [signOut, handleSignIn]);

  useEffect(() => {
    const verifyReSignIn = async () => {
      const oobCode = searchParams.get('oobCode');
      if (oobCode == null) {
        return;
      }

      // Email sign-in
      const currentUser = await userService.getUser();
      if (currentUser == null) {
        handleSignIn('email');
        return;
      }

      if (isSubmittingRef.current) {
        return;
      }

      setReSigninModalOpen(true);
    };

    verifyReSignIn();
  }, [searchParams, handleSignIn, authService, userService, isSubmittingRef]);

  const handleInvitedSignIn = useCallback(() => {
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate]);

  return (
    <Container>
      <SignInContent>
        <HiddenView hide={step === 'choose-providers'}>
          <RMBackButton onClick={() => setStep('choose-providers')} />
        </HiddenView>

        <Logomark src={LogomarkIcon} alt="Remento logomark" />
        <RMSpacer spacing="2xl" direction="column" />
        <Wordmark />
        <RMSpacer spacing="3xl" direction="column" />

        {step === 'choose-providers' && (
          <>
            {isInvite ? (
              <>
                <RMText type="serif" size="l" color="on-surface-primary">
                  Sign up to join {genitiveCase(inviteTokenPayload?.data.name.first)} project and view their stories
                </RMText>
              </>
            ) : (
              <RMText type="serif" size="xl">
                Sign in
              </RMText>
            )}
            <RMSpacer spacing="2xl" direction="column" />
            <InputContainer form={form} path="email">
              {(props) => (
                <RMInput
                  id="email"
                  label="Enter your email address"
                  placeholder="johndoe@gmail.com"
                  disabled={isSubmitting || defaultEmail !== null}
                  autoCapitalize="none"
                  type="email"
                  {...props}
                />
              )}
            </InputContainer>
            <RMSpacer spacing="xl" direction="column" />
            <RMButton
              fullWidth
              onClick={handleSendSigninEmailLink}
              background="primary"
              autoLoading
              disabled={!isValidForm}
            >
              Continue
            </RMButton>
            {isInApp() === false && defaultEmail === null && (
              <>
                <RMSpacer spacing="xl" direction="column" />
                <Divider>
                  <div />
                  <RMText type="sans" size="xs" bold>
                    or
                  </RMText>
                  <div />
                </Divider>
                <RMSpacer spacing="xl" direction="column" />
                <RMButton leftIcon={GoogleLogo} fullWidth onClick={() => handleSignIn('google')} autoLoading>
                  Continue with Google
                </RMButton>
              </>
            )}

            {isInvite && (
              <SignInLink>
                <RMText align="center" type="sans" size="xs" color="on-surface-secondary">
                  Already have an account? <LoginLink onClick={handleInvitedSignIn}>Sign in</LoginLink>.
                </RMText>
              </SignInLink>
            )}
          </>
        )}

        {step === 'email-sent' && (
          <>
            <RMText type="serif" size="m" style={{ wordBreak: 'break-word' }}>
              We sent a confirmation link to {formValues?.email ?? ''}.
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s">
              Please tap the link we sent. Don&apos;t see the email? Please check your spam folder.
            </RMText>
            <RMSpacer spacing="4xl" direction="column" />
            {isGmail && (
              <a href={`https://mail.google.com/mail/u/${formValues?.email}/#search/in%3Aall+remento`}>
                <RMButton fullWidth>Open Email App</RMButton>
              </a>
            )}
          </>
        )}
      </SignInContent>

      <ReSignInModal open={reSigninModalOpen} onReSignIn={handleReSignIn} />
      <InvalidEmailLinkModal open={invalidEmailLinkModalOpen} />
    </Container>
  );
}

export function SigninPage({ isInvite }: SignInProps) {
  return (
    <RementoPage type="default">
      <Signin isInvite={isInvite} />
    </RementoPage>
  );
}
